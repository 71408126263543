import React from 'react';
import styled from 'styled-components';

const SpanWithMargin = styled.span`
    margin-right: 5px;
`;

const Name = styled.span`
    font-weight: bold;
`;

const CenteredParagraph = styled.p`
    text-align: center;
    margin: 0;
`;

const CenteredLink = styled.a`
    text-align: center;
    display: block;
`;

const DivWithMargin = styled.div`
    margin-bottom: 20px;
`;

export const Card = ({
    options: { title, name, languages, site, tel, city },
}) => (
    <DivWithMargin>
        <CenteredParagraph>
            <SpanWithMargin>{title}</SpanWithMargin>
            <Name>{name}</Name>
        </CenteredParagraph>
        <CenteredParagraph>({languages})</CenteredParagraph>
        {site ? <CenteredLink href={site}>{site}</CenteredLink> : ''}
        <CenteredParagraph>
            <SpanWithMargin>Tel: {tel}</SpanWithMargin>
            <span>{city}</span>
        </CenteredParagraph>
    </DivWithMargin>
);

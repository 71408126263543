import React from 'react';
import styled from 'styled-components';

const CenteredParagraph = styled.p`
    text-align: center;
    margin: 0;
`;

const CenteredLink = styled.a`
    text-align: center;
    display: block;
    margin-bottom: 10px;
`;

export const Link = ({ options: { name, link } }) => (
    <div>
        <CenteredParagraph>{name}</CenteredParagraph>
        <CenteredLink href={link}>{link}</CenteredLink>
    </div>
);

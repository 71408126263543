import en from './en';
import nl from './nl';
import ru from './ru';
import fr from './fr';

export default {
    en,
    nl,
    ru,
    fr,
};

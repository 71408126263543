import React from 'react';
import styled from 'styled-components';
import { Title } from './title';
import { Table } from './table';
import { Column } from './column';
import { Card } from './card';
import { Link } from './link';

const CenteredParagraph = styled.p`
    text-align: center;
`;

const JustifiedParagraph = styled.p`
    text-align: justify;
`;

const CenteredDiv = styled.div`
    text-align: center;
`;

const Email = styled.span`
    margin: 10px 0;
    text-align: center;
    display: block;

    span {
        margin-right: 5px;
    }
`;

const inner = ({ type, name, value }) => {
    switch (type) {
        case 'link':
            return (
                <a href={value} target="new">
                    {name}
                </a>
            );
        case 'email':
            return <a href={`mailto:${value}`}>{name}</a>;
        default:
            throw Error(`Unknown inner type: ${type}`);
    }
};

export const DynamicComponent = ({ options }) => {
    const { type, values } = options;

    switch (type) {
        case 'title':
            return values.map(v => <Title>{v}</Title>);
        case 'card':
            return values.map(o => <Card options={o} />);
        case 'link':
            return values.map(o => <Link options={o} />);
        case 'table':
            return (
                <Table>
                    {values.map(v => (
                        <DynamicComponent options={v} />
                    ))}
                </Table>
            );
        case 'column':
            return (
                <Column>
                    {values.map(v => (
                        <DynamicComponent options={v} />
                    ))}
                </Column>
            );
        case 'paragraph':
            return values.map(v => (
                <JustifiedParagraph>{v}</JustifiedParagraph>
            ));
        case 'paragraph-centered':
            return values.map(v => <CenteredParagraph>{v}</CenteredParagraph>);
        case 'paragraph-inner':
            return values.map(v => {
                const { ps: parts, p: paragraph } = Object.entries(
                    v.inner
                ).reduce(
                    ({ ps, p }, [key, val]) => {
                        const [before, after] = p.split(`{${key}}`);
                        return {
                            ps: [...ps, <span>{before}</span>, inner(val)],
                            p: after,
                        };
                    },
                    { ps: [], p: v.paragraph }
                );
                return (
                    <p>
                        {parts}
                        <span>{paragraph}</span>
                    </p>
                );
            });
        case 'email':
            return values.map(email => (
                <Email>
                    <span>Email:</span>
                    <a href={`mailto:${email}`}>{email}</a>
                </Email>
            ));
        case 'map':
            return values.map(v => (
                <CenteredDiv>
                    <iframe
                        title="address"
                        src={v}
                        width="100%"
                        height="400"
                        frameBorder="0"
                        allowFullScreen=""
                    ></iframe>
                </CenteredDiv>
            ));
        case 'list':
            return (
                <ul>
                    {values.map((v, i) => (
                        <li key={i}>{v}</li>
                    ))}
                </ul>
            );
        case 'list-numbered':
            return (
                <ol>
                    {values.map((v, i) => (
                        <li key={i}>{v}</li>
                    ))}
                </ol>
            );

        default:
            throw Error(`Unknown content type: ${type}`);
    }
};
